import { CurrencySchema, DateSchema, IReportSchema, NumberSchema, TextSchema } from './generics'

export const salesSchema: IReportSchema[] = [
  TextSchema('Tipo de adquisición', 'tipo_adquision', { bold: true, width: 20 }),
  TextSchema('Ejecutivo de adquisición', 'ejecutivo_adquision', { width: 24 }),
  TextSchema('Auto', 'auto', { width: 10 }),
  TextSchema('Descripción', 'descripcion', { width: 20 }),
  TextSchema('Negocio', 'negocio', { width: 20 }),
  CurrencySchema('Precio de compra', 'precio_compra', { width: 20 }),
  CurrencySchema('Costo de habilitación', 'costo_habilitacion', { width: 20 }),
  CurrencySchema('Precio de lista', 'precio_lista', { width: 20 }),
  CurrencySchema('Descuento autorizado', 'descuento_autorizado', { width: 20 }),
  CurrencySchema('Bono de renovación', 'bono_renovacion', { width: 20 }),
  CurrencySchema('Venta sin descuento', 'venta_sin_descuento', { width: 20 }),
  CurrencySchema('Descuento otorgado', 'descuento_otorgado', { width: 20 }),
  CurrencySchema('Traspaso', 'traspaso', { width: 20 }),
  NumberSchema('Evaluación', 'evaluation', { width: 20 }),
  NumberSchema('N° de operación', 'nro_operacion', { width: 20 }),
  CurrencySchema('Comision', 'comision', { width: 20 }),
  TextSchema('Ejecutivo', 'ejecutivo', { width: 20 }),
  DateSchema('Fecha de venta', 'fecha_venta', { width: 20 }),
  NumberSchema('ID de venta', 'id_venta', { width: 20 }),
  TextSchema('Documento de soporte', 'documento_soporte', { width: 20 }),
]
