import { DateSchema, FloatSchema, IReportSchema, NumberSchema, TextSchema } from './generics'

export const inspectionsSchema: IReportSchema[] = [
  NumberSchema('Inspección', 'inspeccion', { bold: true, width: 10 }),
  TextSchema('Auto', 'auto', { width: 10 }),
  TextSchema('Descripción', 'descripcion', { width: 45 }),
  DateSchema('Fecha de de inicio', 'fecha_inicio_inspection', { width: 20 }),
  FloatSchema('Calificación', 'calificacion', { width: 20 }),
  TextSchema('Inspector', 'inspector', { width: 20 }),
]
