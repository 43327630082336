
  import { utcToLocal } from '@/utils/general'
  import dayjs from 'dayjs'
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { mapActions } from 'vuex'
  import writeXlsxFile from 'write-excel-file'
  import { inspectionsSchema } from './Schemas/inspections'
  import { purchasesSchema } from './Schemas/purchases'
  import { salesSchema } from './Schemas/sales'

  @Component({
    methods: {
      ...mapActions('monthlyReports', [
        'getMonthlyReportSales',
        'getMonthlyReportPurchases',
        'getMonthlyReportInspections',
      ]),
    },
  })
  export default class MonthlyReport extends Vue {
    getMonthlyReportSales!: (variable) => Promise<Record<string, any>>
    getMonthlyReportPurchases!: (variable) => Promise<Record<string, any>>
    getMonthlyReportInspections!: (variable) => Promise<Record<string, any>>

    loading: Boolean = false
    @Prop({ default: undefined }) dates

    async generateReport () {
      const { dates, datesFilter, getHeaderStyle, byExecutive, byInspector, byDateField } = this
      if (!dates) {
        console.error('Error generating monthly report: Dates are not specified')
        return
      }

      this.loading = true
      const reports = {
        sales: (await this.getMonthlyReportSales(datesFilter))?.records
          ?.sort(byDateField('fecha_venta'))
          ?.sort(byExecutive) || [],
        purchases: (await this.getMonthlyReportPurchases(datesFilter))?.records
          ?.sort(byDateField('fecha_compra'))
          ?.sort(byExecutive) || [],
        inspections: (await this.getMonthlyReportInspections(datesFilter))?.records
          ?.sort(byDateField('fecha_inicio_inspection'))
          ?.sort(byInspector) || [],
      }

      await writeXlsxFile([reports.sales, reports.purchases, reports.inspections], {
          schema: [salesSchema, purchasesSchema, inspectionsSchema],
          sheets: ['Ventas', 'Compras', 'Inspecciones'],
          fileName: 'Reporte mensual.xlsx',
          fontFamily: 'Poppins',
          getHeaderStyle,
        }
      )

      this.loading = false
    }

    byDateField (field) {
      return (prev, next) => {
        if (dayjs(next[field]).isBefore(dayjs(prev[field]))) {
          return 1
        } else if (dayjs(next[field]).isAfter(dayjs(prev[field]))) {
          return -1
        }
        return 0
      }
    }

    byExecutive (prev, next) {
      if (next.ejecutivo < prev.ejecutivo) {
        return 1
      } else if (next.ejecutivo > prev.ejecutivo) {
        return -1
      }
      return 0
    }

    byInspector (prev, next) {
      if (next.inspector < prev.inspector) {
        return 1
      } else if (next.inspector > prev.inspector) {
        return -1
      }
      return 0
    }

    getHeaderStyle (columnStyle) {
      return {
        backgroundColor: '#6185DB',
        color: '#ffffff',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        align: columnStyle.align,
        indent: columnStyle.indent,
      }
    }

    get datesFilter () {
      return {
        start_date: utcToLocal(this.dates?._gte),
        end_date: utcToLocal(this.dates?._lte),
      }
    }

    get isSupervisor () {
      const { $route } = this

      return $route.path.includes('supervisor')
    }
  }
