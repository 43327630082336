import { CurrencySchema, DateSchema, IReportSchema, TextSchema } from './generics'

export const purchasesSchema: IReportSchema[] = [
  TextSchema('Tipo de adquisición', 'tipo_adquision', { bold: true, width: 20 }),
  TextSchema('Auto', 'auto', { width: 10 }),
  TextSchema('Descripción', 'descripcion', { width: 45 }),
  DateSchema('Fecha de compra', 'fecha_compra', { width: 20 }),
  CurrencySchema('Monto autorizado', 'monto_autorizado', { width: 20 }),
  CurrencySchema('Monto compra', 'monto_compra', { width: 20 }),
  TextSchema('Documento de soporte', 'documento_soporte', { width: 20 }),
  TextSchema('Ejecutivo', 'ejecutivo', { width: 20 }),
]
