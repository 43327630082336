import { utcToLocal } from '@/utils/general'
import { Constructor } from 'vue/types/options'

export const format = {
  date: 'dd/mm/yyyy',
  currency: '#,##0',
}

interface IBaseReportSchema {
  column: String
  value: Function
  fontWeight?: String
  width?: Number
  color?: String
}

export interface IReportSchema extends IBaseReportSchema {
  type: Constructor
  format?: String
}

export interface ISchemaOptions {
  bold?: Boolean
  width?: Number
}

export const BaseSchema = (columnName: string, fieldName: string, opts?: ISchemaOptions): IBaseReportSchema => {
  return {
    column: columnName,
    value: item => item[fieldName],
    fontWeight: opts?.bold ? 'bold' : undefined,
    width: opts?.width,
    color: '#737373',
  }
}

export const TextSchema = (columnName: string, fieldName: string, opts?: ISchemaOptions): IReportSchema => {
  return {
    ...BaseSchema(columnName, fieldName, opts),
    type: String,
  }
}

export const NumberSchema = (columnName: string, fieldName: string, opts?: ISchemaOptions): IReportSchema => {
  return {
    ...BaseSchema(columnName, fieldName, opts),
    type: Number,
    value: item => {
      if (isNaN(item[fieldName])) {
        return undefined
      }
      return parseInt(item[fieldName]) || undefined
    },
  }
}

export const FloatSchema = (columnName: string, fieldName: string, opts?: ISchemaOptions): IReportSchema => {
  return {
    ...NumberSchema(columnName, fieldName, opts),
    value: item => {
      if (isNaN(item[fieldName])) {
        return undefined
      }
      return parseFloat(item[fieldName]) || undefined
    },
  }
}

export const DateSchema = (columnName: string, fieldName: string, opts?: ISchemaOptions): IReportSchema => {
  return {
    ...BaseSchema(columnName, fieldName, opts),
    type: Date,
    value: item => new Date(utcToLocal(item[fieldName]).format('YYYY-MM-DD')),
    format: format.date,
  }
}

export const CurrencySchema = (columnName: string, fieldName: string, opts?: ISchemaOptions): IReportSchema => {
  return {
    ...NumberSchema(columnName, fieldName, opts),
    format: format.currency,
  }
}
